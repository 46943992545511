
import { defineComponent } from 'vue';
import { Superlearning } from '@/models/Superlearning';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import { backendUrl, CommonError, errorPrepend } from '@/models';
import NoContent from '@/components/common/NoContent.vue';

export default defineComponent({
  name: 'AudioManagement',
  components: { NoContent },
  data () {
    return {
      newAudio: { name: '', audio: {} as File },
      audios: [] as Superlearning[]
    };
  },
  mounted () {
    this.getAudios();
  },
  methods: {
    async createNew () {
      const files = (this.$refs.fileInput as HTMLInputElement).files;
      try {
        if (this.newAudio.name.trim() === '' || !files || files?.length === 0) {
          await showError(this.$t("messages.fill all fields"));
          return;
        }
        this.newAudio.audio = files[0];
        if (this.newAudio.audio.size > 41943040) {
          await showError(this.$t("messages.file to big"));
          return;
        }
        await Superlearning.create(this.newAudio);
        this.newAudio = { name: '', audio: {} as File };
      } catch (e) {
        await showError(this.$t("messages.no upload audio") + errorPrepend());
      }
      await this.getAudios();
    },
    async deleteAudio (audioId: number) {
      const result = await confirmDelete(this.$t("messages.Delete?"), this.$t("messages.del music"));
      if (result.isDismissed) {
        return;
      }
      try {
        await Superlearning.delete(audioId);
      } catch (e) {
        await showError(this.$t("messages.no del audio") + errorPrepend());
      }
      await this.getAudios();
    },
    async getAudios () {
      try {
        this.audios = await Superlearning.getAll();
      } catch (e) {
        await showError(this.$t("messages.no load audio") + errorPrepend());
      }
    }
  },
  computed: {
    serverUrl () {
      return backendUrl;
    }
  }
});
